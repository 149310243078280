const state = {
    ksCheckedAll: false,
    ksIndeterminate: false,
}

const mutations = {
    changeKsCheckedAll (state, val) {
        state.ksCheckedAll = val
    },
    changeKsIndeterminate(state,val){
        state.ksIndeterminate=val
    }
}
const actions = {


}
const getters = {

}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}